import { ClientConfig } from './config'

const config: ClientConfig = {
  disableModules: ['reports', 'staff', 'suspension', 'lta-memberships', 'organisations', 'areas'],
  clientName: 'ITA',
  logoFilename: 'ita-logo-small.png',
  logoAltText: 'ITA Tennis Logo',
  logoSmallFilename: 'ita-logo-small.png',
  faviconFilename: 'ita-logo-small.png',
  siteTitle: 'ITA Tennis',
  siteDescription: 'ITA Global Admin Dashboard',
  footerCopyright: 'ita copyright',
  ignoreScopes: true,
  hideFacilitySelect: true,
  disableOrgHierarchyRestrictions: true,
  noRootProvider: true
}

export default config
